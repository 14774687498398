// Dependencies
import React, { useState, useEffect } from "react"
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import AnnotatedText from "../components/AnnotatedText"
import Card from "../components/Card"
import Header from "../components/Header"
import Dealer from "../components/Dealer"
import Layout from "../components/Layout"

const NotFoundPage = () => {

  // Content
  const { adviceCategories, pageNotFound } = useSettings();
  const randomAdvice = adviceCategories.map((category) => {
    const selection = category.advice[Math.floor(Math.random() * category.advice.length)]
    return selection
  });

  // UX
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true)
  }, []);

  return (
    <Layout>
      <Header />

      <div className="container" style={{ marginBottom: '50vh' }}>
        <div className="grid has-dark-links">
          <div className="col-4 p2">
            <AnnotatedText richText={pageNotFound} />
          </div>

          <div className="col-2"></div>
        </div>
      </div>

      {ready && (
        <Dealer>
          {randomAdvice.map((entry, index) =>  (
            <Card {...entry} key={`404-card-${entry.slug}-${index}`} />
          ))}
        </Dealer>
      )}

    </Layout>
  )
}

export default NotFoundPage
