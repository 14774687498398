// Dependencies
import React, { useRef } from "react";
import { graphql, Link } from "gatsby";
// Hooks & Helpers
import isLightOrDark from "../helpers/isLightOrDark";
import useWindowSize from "../hooks/useWindowSize";
import useRandomItem from "../hooks/useRandomItem";
import useSettings from "../hooks/useSettings";
// Components
import Markdown from "../components/Markdown";
import Stamp from "../components/Stamp";
import AnnotatedText from "../components/AnnotatedText";

// Constants
const RATIO = 61;

// Helpers
function useRandomPosition(scale) {
  scale = scale || 300;
  const position = Math.floor((Math.random() * scale) - (scale / 2));
  return position;
}

const CardStamp = ({ category }) => {

  const translate = useRef({
    top: Math.floor(Math.random() * 0),
    right: Math.floor(Math.random() * 40),
    left: Math.floor(Math.random() * 40),
    bottom: Math.floor(Math.random() * -8)
  })

  const styles = [{
    left: `${translate.current.left}%`,
    bottom: `${translate.current.bottom}%`,
    transform: `translateY(100%)`
  }];

  const style = useRandomItem(styles);

  return (
    <div className="card-stamp" style={style}>
      <Stamp category={category} randomize />
    </div>
  )
}

const AdviceMeta = ({ colorShade, source, tags, category, slug, moreAboutThis }) => {

  return (
    <div className={`advice-meta pabs b0 r0 padx2  ${colorShade === 'light' ? 'has-light-links' : 'has-dark-links'}`}>
      {source && (
        <div className="advice-source outer">
          <div className="inlb align-right label mr">
            Source:
          </div>
          <div className="inlb links">
            {source.link ? (
              <a href={source.link} target="_blank" rel="noreferrer noopener">{source.outlet}</a>
            ) : (
              <span>{source.outlet}</span>
            )}
          </div>
        </div>
      )}

      {tags && (
        <div className="advice-tags outer">
          <div className="inlb align-right label mr">
            Tags:
          </div>
          <div className="inlb links">
            {tags.map((entry, index) => (
              <Link to={`/advice?${entry.name}`} key={`${slug}-tag-${index}`}>{entry.name}</Link>
            ))}
          </div>
        </div>
      )}

      <div className="advice-mobile-link align-right">
        <Link to={`/advice/${category[0].slug}/${slug}`}>{moreAboutThis}</Link>
      </div>
    </div>
  )
}

// Component
const Card = (props) => {

  // Content
  const { width } = useWindowSize();
  const { moreAboutThis } = useSettings();
  const characterLimit = width < 768 || props.isSmall ? 200 : 325;
  const isLandscape = props.quote.text.length < characterLimit ? true : false;

  // UX
  // ...

  // Style
  // COLOUR
  const { color, palette } = props.category[0];
  const colorShade = isLightOrDark(palette.color);
  const isLight = colorShade === 'light';
  const backgrounds = isLight ? [palette.dark, palette.darkest] : [palette.lightest, palette.light];
  const randomBackgroundColor = useRandomItem(backgrounds);
  const annotationColor = !isLight ? palette.darkest : palette.light;
  // POSITIONING
  const randomPosition = useRef(useRandomPosition());
  const translateX = () => props.randomizePosition && width > 768 ? randomPosition.current : 0;

  return (
    <div className={`card ${props.isSmall ? 'small' : 'large'} ${colorShade}`} style={{
      color: palette.color,
      backgroundColor: !props.inCategoryFolder ? palette.primary : randomBackgroundColor,
      transform: `translateX(${translateX()}px)`,
      ...props.style
    }} ref={props.setRef || null}>
      <div className={isLandscape ? 'landscape-pad' : 'portrait-pad'} />
      <div className="card-contents has-indents pabs fs">

        {/* Category title */}
        <h4>
          Paul on… <Link to={`/advice/${props.category[0].slug}`} className="upcase">{props.category[0].title}</Link>
        </h4>

        {/* Quote Text */}
        <div className="prel">
          <AnnotatedText className="quote" field={props.quote} richText={props.quoteWithAnnotations} color={annotationColor} />

          {/* Random Stamp */}
          {/* {!props.inCategoryFolder && (
            <CardStamp category={props.category[0]} />
          )} */}
          <CardStamp category={props.category[0]} />
        </div>

        {/* Source and tags */}
        <AdviceMeta {...props} colorShade={colorShade} moreAboutThis={moreAboutThis} />

        {/* Card link */}
        <div className={`card-link ${colorShade === 'light' ? 'has-light-links' : 'has-dark-links'}`}>
          <h4 className="upcase">
            <Link to={`/advice/${props.category[0].slug}/${props.slug}`}>{moreAboutThis}</Link>
          </h4>
        </div>

      </div>
    </div>
  )
}

export default Card

export const categoryFragment = graphql`
  fragment CategoryFragment on ContentfulCategory {
    title
    slug
    description {
      text: description
    }
    advice {
      ...AdviceFragment
    }
    stampColor {
      file {
        url
      }
    }
    # Palette
    backgroundColor
    color: textColor
    annotationColor
    secondaryBackgroundColor
    # NEW PALETTE
    palette {
      color
      lightest
      light
      primary
      dark
      darkest
    }
  }
`

export const adviceFragment = graphql`
  fragment AdviceFragment on ContentfulAdvice {
    slug
    # QUOTE
    quote {
      text: quote
      childMarkdownRemark {
        html
      }
    }
    quoteWithAnnotations {
      json
    }
    # SOURCE
    source {
      title
      outlet
      link
    }
    # AUDIO
    audioClip {
      title
      mp3 {
        description
        file {
          url
        }
      }
    }
    ### CATEGORY ###
    category {
      title
      slug
      description {
        text: description
      }
      # NEW PALETTE
      palette {
        color
        lightest
        light
        primary
        dark
        darkest
      }
      # Palette
      backgroundColor
      color: textColor
      annotationColor
      secondaryBackgroundColor
      # Stamps
      stampColor {
        file {
          url
        }
      }
    }
    tags {
      name
      synonyms
    }
    images {
      fluid(maxHeight: 500) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
`
