// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
// Hooks
import useEventListener from "../hooks/useEventListener";
import useTimeout from "../hooks/useTimeout";
import useWindowSize from "../hooks/useWindowSize";
// Components
import Card from "../components/Card";

const vh = (fraction) => {
  const isClient = typeof window === 'object';
  if (isClient) {
    return `${window.innerHeight * (fraction / 100)}px`
  } else {
    return `${fraction}vh`
  }
};

const Advice = ({ children, index, debug, finished, setFinished, isLast, stickOnTop }) => {

  // ----------------
  // UX

  // Position/story states...
  const [isReady, setReady] = useState(true); // TODO
  const [checkpoint, setCheckpoint] = useState(0);
  // Postion values
  const [cardTop, setTop] = useState(0);
  const [initPosition, setInitPosition] = useState(0);
  const [finalPosition, setFinalPosition] = useState(0);

  // Refs
  const movingCardRef = useRef();
  const shadowCardRef = useRef();
  const setShadowCardRef = useCallback(node => {
    if (!shadowCardRef.current) {
      shadowCardRef.current = node
    }

    if (node) {
      setInitPosition(node.getBoundingClientRect().top)
    }
  }, []);

  // Some random transforms..
  const randomTransforms = useRef({
    x: Math.floor(Math.random() * 80) - 40,
    leftX: Math.floor(Math.random() * -30),
    rightX: Math.floor(Math.random() * 30),
    y: Math.floor(Math.random() * 100) - 50,
    rotate: Math.floor(Math.random() * 40) - 20
  });

  const handleScroll = () => {

    // Measure position of moving card...
    const movingCard = movingCardRef.current && movingCardRef.current.getBoundingClientRect();
    const shadowCard = shadowCardRef.current && shadowCardRef.current.getBoundingClientRect();

    // CHECKPOINTS
    // 0 = start/bottom
    // 1 = middle/moving
    // 2 = pre-top
    // 3 = stop/top

    if (shadowCard && movingCard) {

      if (movingCard.bottom < 0 && stickOnTop) {
        setCheckpoint(3);
      } else if (movingCard.top < 0) {
        setCheckpoint(2);
      } else if (movingCard.top <= initPosition) {
        setCheckpoint(1);
      } else {
        setCheckpoint(0)
      }
    }
  }

  // Set the global 'finished' state when the last card is dealt
  const handleLastCard = useCallback(() => {
    if (isLast) {
      if (checkpoint > 2) {
        setFinished(true)
      } else {
        setFinished(false)
      }
    }
  }, [isLast, finished, setFinished, checkpoint])
  useEffect(handleLastCard, [checkpoint])

  // Set scroll addEventListener...
  useEventListener('scroll', handleScroll, { passive: true });

  // Show when ready
  // const ready = () => {
  //   setReady(true)
  // }
  // useTimeout(ready, 300, {
  //   persistRenders: true
  // })

  // ----------------
  // CSS
  const { height } = useWindowSize(true);
  const isLeft = randomTransforms.current.rotate < 0;
  const translate3d = (x, y, r) => `translate3d(${x ? x : `${randomTransforms.current.x}vw`}, ${y}, 0) rotate(${r ? r : randomTransforms.current.rotate}deg)`;

  const transforms = isReady ? finished ? {
    transform: translate3d(null, '-100%'),
    transformOrigin: !isLeft ? 'left top' : 'right top',
  } : checkpoint === 0 ? {
    transform: translate3d(null, '0%'),
    transformOrigin: !isLeft ? 'left top' : 'right top',
  } : checkpoint < 3 ? {
    transform: translate3d('0px', '0%', '0'),
    transformOrigin: !isLeft ? 'left top' : 'right top',
  } : checkpoint === 3 ? {
    transform: translate3d('0px', '0%'),
    transformOrigin: !isLeft ? 'left top' : 'right top',
  } : {} : {
    // Is not ready quite yet...
    transform: translate3d(null, '100%'),
    transformOrigin: !isLeft ? 'left top' : 'right top',
  };

  const ON = 1;
  const OFF = 0.5;

  const shadowPosition = checkpoint === 0 ? {
    // isStart
    position: 'fixed',
    // top: '80vh', // height * 0.75
    left: 0,
    zIndex: 98 - index,
    // opacity: ON
  } : checkpoint <= 2 ? {
    // isMIddle
    position: 'absolute',
    top: '0',
    left: 0,
    zIndex: 101,
    // opacity: OFF
  } : checkpoint >= 3 ? {
    // Is End
    position: 'fixed',
    top: '0',
    left: 0,
    transform: `translate3d(0, -100%, 0)`,
    zIndex: 101,
    // opacity: ON
  } : {}

  return (
    <div className={`card-wrapper`}>
      <div className={`moving-card ${debug ? "" : ""}`} ref={movingCardRef}>
        <div className="inlb" style={transforms}>
          {children}
        </div>
      </div>
      <div className={`shadow-card ${debug ? "" : ""}`} ref={setShadowCardRef} style={shadowPosition}>
        <div className="inlb" style={transforms}>
          {children}
        </div>
      </div>
    </div>
  )
};

const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

const Dealer = ({ advice, stickOnTop, children }) => {

  // Content
  // const cards = shuffleArray(React.Children.toArray(children))
  const length = React.Children.length

  // UX
  const [finished, setFinished] = useState(false);

  return (
    <div className="dealer padx2">
      {React.Children.map(children, (child, index) => (
        <Advice
          index={index}
          debug={index === 1}
          isLast={index === length - 1}
          finished={finished}
          setFinished={setFinished}
          stickOnTop={stickOnTop}
          key={`advice-${index}`}
        >
          {child}
        </Advice>
      ))}
    </div>
  )
}

export default Dealer
