// Dependencies
import React, { useRef } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

const Stamp = ({ category, neutral, randomize, onClick }) => {

  // Content
  const src = (category.stampColor && category.stampColor.file.url) || ""

  const translate = useRef({
    x: Math.floor(Math.random() * 100) - 50,
    y: Math.floor(Math.random() * 100) - 50,
    rotate: Math.floor(Math.random() * 50) - 25
  })

  const style = randomize && {
    transform: `rotate(${translate.current.rotate}deg)`
  };

  return (
    <span className={`stamp-svg inlb ${onClick ? "pointer" : ""}`} style={style}>
      <img src={src} onClick={onClick} alt={`Stamp for ${category.title}`} />
    </span>
  )
}

export default Stamp
